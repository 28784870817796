<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <!-- <pre>{{ dbRowData }}</pre> -->
        <p v-html="currentDirectDebit()"></p>
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-row class="mt-4">
              <div class="form-group col-md-12">
                <base-datepicker
                  vid="date"
                  name="date"
                  label="Modifica data di esecuzione mandato"
                  v-model="form[rep].date"
                  :rules="{ required: true }"
                />
              </div>
            </b-row>
            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                variant="lisaweb"
                size="sm"
                :disabled="invalid"
              >
                Salva
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseIcon from "@/components/BaseIcon";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  name: "ChangeDate",
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      changeDatModalId: "changeDateModal",
      isLoading: false,
      repository: "direct_debit",
      resource: "direct_debits",
      form: {
        direct_debit: {
          date: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseDatepicker,
  },
  methods: {
    currentDirectDebit() {
      const direct_debits_id = this?.dbRowData?.id;
      if (!direct_debits_id) {
        return "Nessun flusso non trovato";
      }
      return `ID Flusso: <strong>${this.dbRowData.id}</strong>`;
    },
    showDetails(dbRowData) {
      if (!dbRowData?.details) return "Non ci sono dettagli da mostrare.";
      return atob(dbRowData.details);
    },
    openModal(id) {
      console.log("openModal");
      this.$bvModal.show(id);
    },
    hideModal(id) {
      console.log("hideModal");
      this.$bvModal.hide(id);
    },
    onSubmit() {
      this.isLoading = true;
      let payload = {};
      const direct_debits_id = this?.dbRowData?.id;
      const Repo = RepositoryFactory.get(this.rep);
      if (this.form[this.rep].date !== null) {
        payload = {
          date: this.form[this.rep].date,
        };
        Repo.update_procedure_date(direct_debits_id, payload)
          .then(() => {
            this.$emit("fetch");
            this.$showSnackbar({
              preset: "success",
              text: "Data modificata con successo",
            });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          })
          .finally(() => {
            this.isLoading = false;
            // this.hideModal(this.groupModalId);
            this.hideModal("modal");
          });
      }
    },
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>

<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-overlay center rounded="sm">
      <!-- <div v-if="!isLoading"> -->
      <b-row>
        <b-col align="left">
          <b-button-group
            v-b-toggle:collapse-1
            class="my-2 filter-button-group"
            :title="getDefaultFilterMessage()"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Filtra
          </b-button-group>
        </b-col>
        <b-col align="right">
          <b-button
            :id="`popover-create`"
            class="btn-create"
            type="button"
            variant="primary"
            size="sm"
            @click="onAdd"
            title="Crea"
            v-if="canVerb(resource, 'store')"
            ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
            Crea</b-button
          >
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-1">
        <b-form @submit.prevent="onSearch(filterName)">
          <b-card class="filter">
            <b-row>
              <div class="col-md-3">
                <base-select
                  name="bank"
                  label="Banca"
                  :options="bank_accounts"
                  v-model="filter.byBankAccount.id"
                />
              </div>
              <div class="col-md-3">
                <!-- <base-datepicker
                  name="setup_at"
                  label="Data istanziamento SDD"
                  v-model="filter.byAttribute.setup_at"
                ></base-datepicker> -->
                <base-month-picker
                  name="setup_at"
                  label="Data istanziamento SDD"
                  v-model="filter.byAttribute.setup_at"
                />
              </div>
              <div class="form-group col-md-3 align-self-end">
                <b-button
                  type="submit"
                  variant="lisaweb"
                  size="sm"
                  v-b-toggle:collapse-1
                  >Cerca</b-button
                >
                <b-button
                  class="btn-reset"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  @click="onClearFilter(filterName)"
                  >Reset</b-button
                >
              </div>
            </b-row>
          </b-card>
        </b-form>
      </b-collapse>

      <direct-debits-table
        @edit="onEdit"
        @download="downloadXml"
        :fields="fields"
        :repository="repository"
        :resource="resource"
        :filterName="filterName"
        :filterOn="{
          byRelations: ['byBankAccount', 'byPaymentProcedure'],
        }"
        @destroy="onDestroy"
        :ref="tableRef"
        :onlyActions="['edit', 'destroy']"
      ></direct-debits-table>

      <div>
        <multi-select-option-modal
          title="Creazione nuovo flusso"
          description=""
          @create="create"
        />
      </div>
      <!-- </div> -->
      <!-- <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template> -->
    </b-overlay>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import DirectDebitsTable from "@/components/tables/DirectDebits.vue";
import DocumentsMixin from "@/mixins/DocumentsMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseSelect from "@/components/form/BaseSelect";
// import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseMonthPicker from "@/components/form/BaseMonthPicker";
import MultiSelectOptionModal from "@/components/modals/multiSelectOption";
import { mapGetters, mapActions } from "vuex";
import { toLocaleDate } from "@/utils/dates";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [CustomFiltersMixin, DocumentsMixin, FormMixin],
  data() {
    return {
      // companies: [],
      isLoading: false,
      bank_accounts: [],
      repository: "direct_debit",
      resource: "direct_debits",
      tableRef: "DirectDebitsTableRef",
      filterName: "filterDirectDebits",
      filter: this.initFilter(),
      fields: [
        {
          key: "id",
          label: this.getDictionary("id", "direct_debit"),
        },
        {
          key: "bank_account.formatted_title",
          label: this.getDictionary("bank_account_id", "direct_debit"),
        },
        {
          key: "setup_at",
          formatter: (value) => toLocaleDate(value, "MM/YYYY"),
          label: this.getDictionary("setup_at", "direct_debit"),
        },
        {
          key: "payment_procedure_count",
          label: "Quietanze nel flusso in partenza / escluse / totali",
          // label: this.getDictionary("payment_procedure_count", "direct_debit"),
        },
        {
          key: "download_xml",
          label: this.getDictionary("download_xml", "direct_debit"),
        },
      ],
      setupAtMoment: {},
      // selects: [
      //   {
      //     title: "Banca",
      //     options: [
      //       { text: "Banca 1", value: 1 },
      //       { text: "Banca 2", value: 2 },
      //     ],
      //     required: true,
      //   },
      //   {
      //     title: "Compagnia",
      //     options: this.companies,
      //     required: false,
      //   },
      // ],
    };
  },
  components: {
    DirectDebitsTable,
    BaseSelect,
    // BaseDatepicker,
    BaseMonthPicker,
    MultiSelectOptionModal,
  },
  methods: {
    toLocaleDate,
    downloadXml(item) {
      item.title = `SEPA_${item.id}_${moment(item.setup_at).format(
        "YYYYMM"
      )}_${moment().format("YYYYMMDDHHmmss")}`;
      item.extension = "xml";
      this.downloadDocumentSepa(item, "payment_procedure");
    },
    create(form) {
      this.$refs[this.tableRef].isLoading = true;
      this.store(this.repository, form)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Flusso creato correttamente",
          });
          // let procedure_id = response.data.data.id;
          // this.onEdit(procedure_id, response.data.data);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.$refs[this.tableRef].isLoading = false;
        });
    },
    initFilter() {
      let init = {
        byAttribute: {
          setup_at: null,
        },
        byBankAccount: {
          id: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${id}` },
      });
    },
    onAdd() {
      this.$bvModal.show("selectOption");
      //this.$router.push({ name: `${this.resource}.create` });
    },
    onSelectOption(val) {
      console.debug("onSelectOption: ", val);
      if (val != null) {
        // const Repo = RepositoryFactory.get(this.repository);
        this.isLoading = true;
        this.fetchCreateForm(this.repository).then(() => {
          // this.form[this.repository].communication_type = val;
          // this.form[this.repository].status_procedure = 1;
          // this.form[this.repository].is_vehicle_premium_shown = 1;
          // this.form[this.repository].is_life_premium_shown = 1;
          // this.form[this.repository].is_extra_vehicle_premium_shown = 1;

          console.log(this.form[this.repository]);
          this.store(this.repository)
            .then((response) => {
              this.isLoading = false;
              this.$showSnackbar({
                preset: "success",
                text: "Flusso creato correttamente",
              });
              let procedure_id = response.data.data.id;
              this.onEdit(procedure_id, response.data.data);
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
              this.isLoading = false;
            });
        });
        // this.showConfirm({
        //   yesCallback: () => {
        //     this.saveSelected();
        //   },
        //   // noCallback: () => {
        //   //   this.$bvModal.hide("createInsurancePaymentOptionsModal");
        //   // },
        //   noCallback: null,
        //   title: "Conferma",
        //   message: "Procedere con l'incasso?",
        //   yesLabel: "PROCEDI",
        //   yesVariant: "outline-lisaweb",
        //   noLabel: "ANNULLA",
        //   // yesVariant:
        //   // noVariant:
        //   // headerBgVariant:
        // });
      } else {
        this.$showSnackbar({
          preset: "info",
          text: "Selezionare il tipo",
        });
      }
    },
    onDestroy(id) {
      this.$refs[this.tableRef].isLoading = true;
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Flusso eliminato con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.$refs[this.tableRef].isLoading = false;
        });
    },
    onSearch(name) {
      if (
        typeof this.filter?.byAttribute?.setup_at !== "undefined" &&
        this.filter?.byAttribute?.setup_at !== null
      ) {
        const setup_at_moment = moment(this.filter.byAttribute.setup_at).format(
          "YYYY-MM"
        );
        this.setupAtMoment = this.filter.byAttribute.setup_at;
        this.filter.byAttribute.setup_at = setup_at_moment;
      }
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch().then(() => {
        if (Object.keys(this.setupAtMoment).length)
          this.filter.byAttribute.setup_at = this.setupAtMoment;
      });
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", {
      // getInsurers: "insurers",
      getBankAccounts: "bank_accounts",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
  created() {
    this.bank_accounts = this.getBankAccounts();
    // this.companies = this.getInsurers();
  },
};
</script>
